import React from "react"
import { NestedForm } from "../../../../../../utils/nested-form"
import InputField from "../../../../../molecules/input"
import Checkbox from "../../../../../atoms/checkbox"

export type PriceFormType = {
  price: number | null
  compareAtPrice: number | null
  tax: boolean | null
  costPerItem: number | null
  profit: number | null
  margin: number | null
}

type Props = {
  form: NestedForm<PriceFormType>
}

const Price = ({ form }: Props) => {
  const { control, path, register } = form
  return (
    <div className="mt-6">
      <div className=" grid grid-cols-3 gap-4 mt-4">
        <InputField
          type="number"
          className="grid-col-1"
          label="Price"
          placeholder="Enter price"
          {...register(path("price"), {
            required: "This field is required and must great than 0",
          })}
        />
        <InputField
          type="number"
          className="grid-col-1"
          label="Compare at price"
          placeholder="Enter price"
          {...register(path("compareAtPrice"))}
        />
      </div>
      <Checkbox
        className="mt-4"
        {...register(path("tax"))}
        label={"Charge tax on this product"}
      ></Checkbox>
      <div className=" grid grid-cols-3 gap-4 mt-4">
        <InputField
          type="number"
          className="grid-col-1"
          label="Cost per item"
          placeholder="Enter cost"
          {...register(path("costPerItem"))}
        />
        <InputField
          type="number"
          className="grid-col-1"
          label="Profit"
          placeholder="_ _"
          {...register(path("profit"))}
        />
        <InputField
          type="number"
          className="grid-col-1"
          label="Margin"
          placeholder="_ _"
          {...register(path("margin"))}
        />
      </div>
    </div>
  )
}

export default Price
