import { navigate, RouteComponentProps, useLocation } from "@reach/router"
import { useAdminCreateBatchJob, useAdminCreateCollection } from "medusa-react"
import React, { useContext, useEffect, useState } from "react"
import Fade from "../../../../atoms/fade-wrapper"
import Button from "../../../../fundamentals/button"
import ExportIcon from "../../../../fundamentals/icons/export-icon"
import PlusIcon from "../../../../fundamentals/icons/plus-icon"
import UploadIcon from "../../../../fundamentals/icons/upload-icon"
import BodyCard from "../../../../organisms/body-card"
import TableViewHeader from "../../../../organisms/custom-table-header"
import ExportModal from "../../../../organisms/export-modal"
import { AddCategoryModal } from "../../../../templates/collection-modal"
import CollectionsTable from "../../../../templates/collections-table"
import ProductTable from "../../../../templates/product-table"
import useNotification from "../../../../../hooks/use-notification"
import useToggleState from "../../../../../hooks/use-toggle-state"
import { getErrorMessage } from "../../../../../utils/error-messages"
import ImportProducts from "../batch-job/import"
import NewProduct from "../new"
import { PollingContext } from "../../../../../context/polling"
import { Tab, Tabs, TabsHeader } from "@material-tailwind/react"
import { AddProductModal } from "../../../../templates/add-products-modal"

const VIEWS = ["products", "collections"]

const Overview = (_props: RouteComponentProps) => {
  const [view, setView] = useState("products")
  const {
    state: createProductState,
    close: closeProductCreate,
    open: openProductCreate,
  } = useToggleState()
  // const { resetInterval } = useContext(PollingContext)
  const createBatchJob = useAdminCreateBatchJob()

  // useEffect(() => {
  //   if (location.search.includes("?view=collections")) {
  //     setView("collections")
  //   }
  // }, [location])

  // useEffect(() => {
  //   location.search = ""
  // }, [view])

  const CurrentView = () => {
    switch (view) {
      case "products":
        return <ProductTable />
      default:
        return <CollectionsTable />
    }
  }

  const CurrentAction = () => {
    switch (view) {
      case "products":
        return (
          <div className="flex space-x-2">
            <Button
              variant="secondary"
              size="small"
              onClick={() => openImportModal()}
            >
              <UploadIcon size={20} />
              Import Products
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={() => openExportModal()}
            >
              <ExportIcon size={20} />
              Export Products
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={openProductCreate}
            >
              <PlusIcon size={20} />
              New Product
            </Button>
          </div>
        )
      default:
        return (
          <div className="flex space-x-2">
            <Button
              variant="secondary"
              size="small"
              onClick={() => setShowNewCollection(!showNewCollection)}
            >
              <PlusIcon size={20} />
              New Collection
            </Button>
          </div>
        )
    }
  }

  const [showNewCollection, setShowNewCollection] = useState(false)
  const {
    open: openExportModal,
    close: closeExportModal,
    state: exportModalOpen,
  } = useToggleState(false)

  const {
    open: openImportModal,
    close: closeImportModal,
    state: importModalOpen,
  } = useToggleState(false)

  // const handleCreateCollection = async (data, colMetadata) => {
  //   const metadata = colMetadata
  //     .filter((m) => m.key && m.value) // remove empty metadata
  //     .reduce((acc, next) => {
  //       return {
  //         ...acc,
  //         [next.key]: next.value,
  //       }
  //     }, {})

  //   await createCollection.mutateAsync(
  //     { ...data, metadata },
  //     {
  //       onSuccess: ({ collection }) => {
  //         notification("Success", "Successfully created collection", "success")
  //         navigate(`/a/collections/${collection.id}`)
  //         setShowNewCollection(false)
  //       },
  //       onError: (err) => notification("Error", getErrorMessage(err), "error"),
  //     }
  //   )
  // }

  const handleCreateExport = () => {
    // const reqObj = {
    //   type: "product-export",
    //   context: {},
    //   dry_run: false,
    // }
    // createBatchJob.mutate(reqObj, {
    //   onSuccess: () => {
    //     resetInterval()
    //     notification("Success", "Successfully initiated export", "success")
    //   },
    //   onError: (err) => {
    //     notification("Error", getErrorMessage(err), "error")
    //   },
    // })
    // closeExportModal()
  }

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="w-full flex flex-col grow">
          <BodyCard
            forceDropdown={false}
            customActionable={CurrentAction()}
            customHeader={
              <TableViewHeader
                views={VIEWS}
                setActiveView={setView}
                activeView={view}
              />
            }
          >
            <CurrentView />
          </BodyCard>
        </div>
      </div>
      <AddCategoryModal
        show={showNewCollection}
        setShow={setShowNewCollection}
        // onClose={() => setShowNewCollection(!showNewCollection)}
        // onSubmit={handleCreateCollection}
      />
      {/* <AddProductModal show={true} setShow={() => { }} /> */}
      {exportModalOpen && (
        <ExportModal
          title="Export Products"
          handleClose={() => closeExportModal()}
          onSubmit={handleCreateExport}
          loading={createBatchJob.isLoading}
        />
      )}
      {importModalOpen && (
        <ImportProducts handleClose={() => closeImportModal()} />
      )}
      <Fade isVisible={createProductState} isFullScreen={true}>
        <NewProduct onClose={closeProductCreate} />
      </Fade>
    </>
  )
}

export default Overview
